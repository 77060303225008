<template>
  <v-container fluid fill-height justify="center">
    <v-row justify="center" class="pa-0 pt-6">
      <v-col cols="12" sm="10" md="8">
        <v-img contain max-height="10vh" :src="this.$store.getters.company.logo"></v-img>
        <v-divider class="pa-3"></v-divider>
        <v-row class="pa-0" v-if="!$vuetify.breakpoint.xsOnly">
          <v-col v-for="(child, index) in items" :key="index" cols="12" md="4" sm="6">
            <v-card elevation="10">
              <v-list-item three-line :to="{ name: child.to, params: child.params }">
                <v-avatar rounded class="ma-3">
                  <v-icon>
                    {{ child.icon }}
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pa-0" v-text="child.name" />
                  <v-list-item-subtitle class="pa-0">
                    <small>{{ child.description }}</small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pa-0" v-else>
          <v-col v-for="(child, index) in items" :key="index" cols="6">
            <v-btn
              class="pa-12"
              large
              block
              :to="{ name: child.to, params: child.params }"
            >
              <span class="text-center">
                <v-icon>{{ child.icon }}</v-icon>
                <br /><br />
                {{ child.name }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";

export default {
  components: {},
  data: () => ({
    categories: [],
    menu: {
      Admin: [
        {
          id: 1,
          name: "Proyectos",
          description: "Seguimiento y creación de eventos",
          icon: "mdi-cog-transfer-outline",
          color: "secondary",
          to: "Projects",
        },
        {
          id: 99,
          name: "Configuración",
          description: "Editar información de contacto",
          icon: "mdi-cog-transfer-outline",
          color: "secondary",
          to: "Settings",
        },
      ],
      Sales: [
        // {
        //   id: 0,
        //   name: "Caja",
        //   description: "Ingresos y Egresos de caja",

        //   color: "secondary",
        //   to: "ClientOrders",
        //   children: docs_lst().docType,
        // },

        // {
        //   id: 4,
        //   name: "Lista de precios",
        //   description: "Creear, editar o eliminar lista de precios ",
        //   icon: "mdi-file-document-edit-outline",
        //   to: "Catalogue",
        //   color: "secondary",
        // },

        {
          id: 5,
          name: "Pedidos",
          description: "Gestionar y crear pedidos",
          icon: "mdi-file-document-multiple-outline",
          color: "secondary",
          to: "docs",
        },
        {
          id: 7,
          name: "Dashboard",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "DashBoard",
        },

        // {
        //   id: 99,
        //   name: "Configuración",
        //   description: "Editar información de contacto",
        //   icon: "mdi-cog-transfer-outline",
        //   color: "secondary",
        //   to: "Settings",
        // },
        {
          id: 91,
          name: "Mi Perfil",
          description: "Editar información de contacto",
          icon: "mdi-store-outline",
          color: "yellow",
          to: "MyStore",
        },

        {
          id: 92,
          name: "Proveedores",
          description: "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-truck-cargo-container",
          color: "secondary",
          to: "Supplier",
        },
        {
          id: 3,
          name: "Catálogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          color: "secondary",
          to: "RawMaterial",
        },
        {
          id: 93,
          name: "Lista de precios",
          description: "Creear, editar o eliminar lista de precios ",
          icon: "mdi-file-document-edit-outline",
          to: "Catalogue",
          color: "secondary",
        },
        {
          id: 94,
          name: "Clientes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-account-group-outline",
          color: "secondary",
          to: "Clients",
        },
      ],
      Sysop: [
        {
          id: 0,
          name: "Ordenes de Clientes",
          description: "Gestión de pedidos de clientes",
          icon: "mdi-truck-cargo-container",
          color: "secondary",
          to: "ClientOrders",
        },
        {
          id: 1,
          name: "Proveedores",
          description: "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-truck-cargo-container",
          color: "secondary",
          to: "Supplier",
        },
        {
          id: 2,
          name: "Catálogo Categorías",
          description: "Creear, editar o eliminar lista de precios ",
          icon: "mdi-file-document-edit-outline",
          to: "Catalogue",
          color: "secondary",
        },
        {
          id: 3,
          name: "Catalogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          color: "secondary",
          to: "RawMaterial",
        },
        {
          id: 4,
          name: "Clientes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-account-group-outline",
          color: "secondary",
          to: "Clients",
        },
        {
          id: 5,
          name: "Documentos",
          description: "Generar de documentos.",
          icon: "mdi-file-document-multiple-outline",
          color: "secondary",
          to: "docs",
        },
        {
          id: 6,
          name: "Documentos Recibidos",
          description: "Facturas recibidas",
          icon: "mdi-inbox-arrow-down-outline",
          color: "secondary",
          to: "invoices_inbox",
        },
        {
          id: 7,
          name: "Dashboard",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "DashBoard",
        },
        {
          id: 8,
          name: "Mi Perfil",
          description: "Editar información de contacto",
          icon: "mdi-store-outline",
          color: "secondary",
          to: "MyStore",
        },
        {
          id: 9,
          name: "Contenidos",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "contents",
        },
        {
          id: 10,
          name: "PUC",
          description: "Plan Unico de Cuentas",
          icon: "mdi-calculator-variant-outline",
          color: "secondary",
          to: "Puc",
        },
      ],
      Owner: [
        {
          id: 0,
          name: "DashBoard",
          description: "Indicadores de la operación",
          icon: "mdi-finance",
          to: "DashBoard",
        },
        {
          id: 1,
          name: "Proveedores",
          description: "Creear, editar o eliminar contactos de proveedores",
          icon: "mdi-store-outline",
          color: "secondary",
          to: "Proveedores",
        },
        {
          id: 2,
          name: "Catalogo Categorias",
          description: "Creear, editar o eliminar lista de precios ",
          icon: "mdi-file-document-edit-outline",
          to: "Catalogue",
          color: "secondary",
        },
        {
          id: 46,
          name: "Catalogo Proveedores",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "secondary",
        },
        {
          id: 55,
          name: "Análisis de precios",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material_prices",
          color: "secondary",
        },
        {
          id: 58,
          name: "Análisis de Mejores precios",
          description: "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material_supplier",
          color: "secondary",
        },
        {
          id: 42,
          name: "Clientes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Clientes",
          color: "secondary",
        },
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "QR4",
          color: "secondary",
        },

        {
          id: 45,
          name: "Nuestra gente",
          description:
            "Creear, editar o eliminar colaboradores, alidos, proveedores logísticos",
          icon: "mdi-account-multiple-outline",
          to: "Empleados",
          color: "secondary",
        },
        {
          id: 44,
          name: "Admin Usuarios",
          description: "Creear, editar o eliminar cuentas de acceso a la plataforma.",
          icon: "mdi-account-lock-outline",
          to: "Usuarios",
          color: "secondary",
        },

        {
          id: 87,
          name: "Mapa",
          description: "Mapa de la georeferenciación de restaurantes y proveedores.",
          icon: "mdi-map-marker-radius",
          color: "secondary",
          to: "ClientsMap",
        },
        {
          id: 88,
          name: "Tarjeta Digital",
          description: "QR con los datos de contacto personal.",
          icon: "mdi-card-account-details-outline",
          to: "BusinessCard",
          color: "secondary",
        },
        {
          id: 90,
          name: "Donde estamos?",
          description: "Donde esta nuestra gente.",
          icon: "mdi-map-marker-radius",
          color: "secondary",
          to: "mypeople",
        },
        {
          id: 91,
          name: "TPV",
          description: "Terminal punto de venta",
          icon: "mdi-map-marker-radius",
          color: "secondary",
          to: "tpv",
        },
        {
          id: 93,
          name: "Mailer",
          description: "Bulk Mailer",
          icon: "mdi-email-fast-outline",
          to: "Marketing",
        },
        {
          id: 94,
          name: "Puntos de Venta",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Pos",
          color: "secondary",
        },
        {
          id: 9,
          name: "Contenidos",
          description: "Resumen operación",
          icon: "mdi-chart-multiple",
          color: "secondary",
          to: "contents",
        },
      ],
    },
    items: [],
  }),
  methods: {
    openLink(e) {
      console.log(e);
      this.$router.push("/" + e + "/");
    },
    get_menu() {
      this.loading_status = true;
      var qry = {
        account: window.company.code,
        table: "fish_docs",
        filters: [
          {
            field: "account",
            operator: "=",
            value: window.company.code,
          },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        data.forEach((rg) => {
          rg.to = "FishEvents";
          rg.params = { code: rg.code, name: rg.name };
        });

        this.menu.Trout = data;
        this.items = this.menu[this.$store.state.profile.user_access];
        this.$store.dispatch("set_menuItems", this.items);
      });
    },
    // get_items() {
    //   this.items_bygroup = null;
    //   var qry = {
    //     account: this.$store.state.profile.account,
    //     table: "stores",
    //     filters: [],
    //   };
    //   if (
    //     this.$store.state.profile.user_access === "Sales" ||
    //     this.$store.state.profile.user_access === "Promotor"
    //   ) {
    //     qry.filters.push({
    //       field: "agent",
    //       operator: "=",
    //       value: this.$store.state.profile.name,
    //     });
    //   }
    //   this.loading_status = true;
    //   webserver("get_table", qry, (data) => {
    //     this.loading_status = false;
    //     data.forEach((rg) => {
    //       rg.date = new Date(rg.issue_date).toISOString();
    //       rg.qty = 1;
    //     });

    //     var now = new Date();
    //     var fromDate = new Date();
    //     fromDate.setDate(fromDate.getDate() - 30);

    //     var lastMonth = [];
    //     for (var d = new Date(fromDate); d <= now; d.setDate(d.getDate() + 1)) {
    //       var dtom = new Date(d);
    //       lastMonth.push(dtom.toISOString());
    //     }

    //     var result = [];
    //     data.reduce(function (res, value) {
    //       if (!res[value.date]) {
    //         res[value.date] = { Id: value.date, qty: 0 };
    //         result.push(res[value.date]);
    //       }
    //       res[value.date].qty += value.qty;
    //       return res;
    //     }, {});
    //   });
    // },
  },
  beforeMount() {},
  mounted() {
    // this.get_menu();
    this.$store.dispatch("setNavStatus", true);
    this.items = this.menu[this.$store.state.profile.user_access];
    this.$store.dispatch("set_menuItems", this.items);
    //this.$store.dispatch("set_menu", true);
  },
  watch: {
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.items = this.menu[newVal.user_access];
        this.$store.dispatch("set_menuItems", this.items);
      }
    },
  },
  computed: {
    // isMobile() {
    //   if (screen.width <= 760) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
};
</script>

<style>
.v-btn__content {
  width: 100% !important;
  white-space: normal !important;
}
</style>
